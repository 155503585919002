import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { AiFillGithub } from 'react-icons/ai'; 

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://twitter.com/Culture_Dlamini">
      <BsTwitter />
      </a>
    </div>
    <div>
      <a href="https://github.com/CodehubNerd/">
      <AiFillGithub />
      </a>
      
    </div>
    <div>
    
    <a href="https://www.instagram.com/masiko_msd/">
    <BsInstagram />
    </a> 
    </div>
  </div>
);

export default SocialMedia;
