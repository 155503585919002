import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';

import { AiOutlineHome} from 'react-icons/ai';
import { CgMoreO} from 'react-icons/cg';
import { GrProjects} from 'react-icons/gr';
import { GiSkills} from 'react-icons/gi';


import {toast} from 'react-hot-toast';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  
  const [toggle, setToggle] = useState(false);
  const [messsge, setMessge] = useState('');
  const alertMessge = (messsge) => {
    setMessge(toast.success('Thanks for Checking out my CV'))
    
  }


  return (
    <>
    
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo"  />
      </div>
      <ul className="app__navbar-links">
        {['home', 'about', 'Projects', 'skills', 'Hire Me'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
       <a href={images.cv} download="images" target='_blank'>
            <button type="button" style={{color:'white',marginBottom:'70px',marginRight:'80px'}} className="p-text btn-nav" onClick={(messsge) => alertMessge()}>Download CV</button>
            </a>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'about', 'Projects', 'skills', 'Hire Me'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>

            <a href={images.cv} download="images" target='_blank'>
            <button type="button" style={{color:'white',marginBottom:'70px',marginRight:'80px'}} className="p-text btn-mobile" onClick={(messsge) => alertMessge()}>Download CV</button>
            </a>

          
          </motion.div>
        )}
      </div>
    </nav>
    <ul className='mobile-list'>
      <li><a href="#home" onClick={() => setToggle(false)}><AiOutlineHome style={{color:'#000040'}}/></a></li>
      <li><a href="#about" onClick={() => setToggle(false)}><CgMoreO style={{color:'#000040'}}/></a></li>
      <li><a href="#Projects" onClick={() => setToggle(false)}><GrProjects style={{color:'#000040'}}/></a></li>
      <li><a href="#skills" onClick={() => setToggle(false)}><GiSkills style={{color:'#000040'}}/></a></li>
      
 
</ul>

    </>
  );
};

export default Navbar;
